import webfontloader from 'webfontloader';
import styles from './DCard.module.css';

webfontloader.load({
  google: {
    families: ['Open Sans', 'Montserrat:700'],
  },
});

function DCard() {
  return (
    <div>
      <div className={styles.header}>
        <h1>D. Deryce</h1>
        <h2>Programmer Analyst</h2>
      </div>
    </div>
  );
}

export default DCard;
